import React from 'react'
import { Link } from 'react-router-dom';

import '../../styles/abogados/AbogadoCard.css';

export const AbogadoCard = ({ 
    id,
    name,
    img,
    description,
    especialidad
 }) => {

    // const imagePath = `/assets/abogados/${img}.jpg`;
    const imagePath = `/assets/abogados/user.png`;

    return (
        <div className='abogado-card animate__animated animate__fadeIn'>
            <img className="abogado-img" src={ imagePath } alt={name}/>
            <div className='abogado-body'>
                <h5 className='abogado-title'>{name}</h5>
                <p className='abogado-desc'>{especialidad}</p>

                <Link to={`/abogado/${id}`} className="abogado-link">
                    Perfil y contacto
                </Link>
            </div>
        </div>
        
        )
}