import React from 'react';
import { createRoot } from 'react-dom/client';
import { SotoIriarteApp } from './SotoIriarteApp';

//Version react 17 
// ReactDOM.render(
//   <SotoIriarteApp />,
//   document.getElementById('root')
// );

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<SotoIriarteApp />);
