// const categoryfamilia = [
//     {
//         id: 'example',
//         name: 'Example',
//         description: 'Example description',
//     },
// ]

export const categoryFamilia = [
    {
        id: 'divorcios',
        name: 'Divorcios',
        description: 'Existen tres tipos de divorcio: unilateral, de común acuerdo y culposo. Todos con el objetivo de disolver legalmente el matrimonio, y además dar fin a los derechos y obligaciones que este genera. Los hijos no se ven afectados por el divorcio. Los padres mantienen obligaciones y derechos respecto de ellos, es decir, ambos progenitores siguen siendo responsables. Siempre deberá contar con un abogado que presente su demanda.'
    },
    {
        id: 'alimentos',
        name: 'Alimentos',
        description: 'El derecho de alimentos, aquel que la ley otorga a una persona para demandar de otra, que cuenta con los medios para proporcionarlos,  lo que necesite para subsistir de un modo correspondiente a su posición social, que debe cubrir a lo menos el sustento, habitación, vestidos, salud, movilización, enseñanza básica, media y educación superior, entre otros.' 
    },
    {
        id: 'visitas',
        name: 'Relación Directa y Regular (Visitas)',
        description: 'El derecho a mantener una relación directa y regular con los hijos es una forma de mitigar los indeseables efectos que para estos produce una ruptura familiar en cuanto a la posterior ausencia de cotidianidad a la que estaban acostumbrados los miembros de la familia.'
    },
    {
        id: 'tuicion',
        name: 'Cuidado Personal (Tuición)',
        description: 'Este derecho significa esencialmente la responsabilidad parental sobre el cuidado y protección de los hijos menores de edad, además de intervenir y participar en la crianza, educación. Este derecho no tan solo pueden ejercerlo los progenitores,  en caso de inhabilidad de ambos, lo puede ejercer un tercero, es decir otro familiar como por ejemplo los abuelos.'
    },
    {
        id: 'paternidad',
        name: 'Acción de Reclamación e Impugnabilidad de Paternidad',
        description: 'La Filiación; es el vínculo de familia existente entre el hijo y sus padres. Respecto del padre se denomina paternidad y respecto de la madre, maternidad. La reclamación de paternidad es una acción que consiste y se basa en el derecho de exigir una filiación a alguien, mientras que la impugnación consiste en la acción y derecho de dejar sin efecto una filiación que ya existía, para luego definir la “correcta”.'
    },
    {
        id: 'Adopción',
        name: 'Adopción',
        description: 'Es el acto mediante el cual se crea un vínculo de parentesco entre dos personas, adoptante y adoptado, de tal forma que genera los mismos deberes, derechos y obligaciones como si fueran padres e hijos biológicos.'
    },
    {
        id: 'intrafamiliar',
        name: 'Violencia Intrafamiliar',
        description: 'La violencia intrafamiliar es entendida como toda acción u omisión cometida por algún miembro de la familia en relación de poder, sin importar el espacio físico donde ocurra, que perjudique el bienestar, la integridad física, psicológica o la libertad y el derecho al pleno desarrollo de otro miembro de la familia. '

    },
    {
        id: 'proteccion',
        name: 'Medidas de Protección',
        description: 'Son aquellas decretadas por el juez del Tribunal de Familia, que tienen por objeto la protección de los derechos de los niños, niñas, y adolescentes, cuando éstos se vean vulnerados, como suspender el derecho de una o más personas determinadas a mantener una relación directa y regular, confiar el cuidado del niño, niña o adolescente a una persona o familia en casos de urgencia, entre otras medidas.'
    },
    {
        id: 'bienes',
        name: 'Bienes familiares',
        description: 'Son los constituidos por el inmueble que sirve de residencia principal a la familia, y los muebles que guarnecen el hogar, y que hayan sido declarado como tales por el juez, los derechos y acciones que los cónyuges tengan en sociedades propietarias del inmueble que sea residencia principal de la familia.'
    },
    {
        id: 'autorizacion',
        name: 'Autorización del menor para salir del país',
        description: 'Si quien detenta el cuidado personal de los hijos menores de 18 años quiere fijar su domicilio fuera del país, por regla general, es necesario que cuente con la aprobación del padre o madre no custodio, de modo que, si debiendo tener esta autorización, el otro progenitor no quiere concederla, es necesario que pida autorización judicial para ello.'
    }
]