import React, { useEffect } from 'react'

import { EspecialidadList } from '../especialidades/EspecialidadList';
import { CarouselScreen } from '../ui/CarouselScreen';
import { AbogadoList } from '../abogados/AbogadoList';


import '../../styles/principal/Principal.css'; 
import { NosotrosScreen } from '../nosotros/NosotrosScreen';
import { PortadaScreen } from '../portada/PortadaScreen';

export const Principal = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);
    return (
        <>
            <PortadaScreen />
            {/* <CarouselScreen /> */}
            <NosotrosScreen />
            <EspecialidadList />

            <div className="div-abogado">
                <h2 className='abogados-title'> Abogados </h2>
                <hr />
            </div>

            <AbogadoList />
        </>
    )
}
