import React from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

import '../../styles/footer/Footer.css';

export const Footer = () => {
	const navigate = useNavigate();

	const imagePath = '/assets/logo/logo4.png';

	const email = "contacto@sotoiriarteabogados.cl";
	const subject = "Consulta sobre servicios";
	const body = "Mucho gusto.\n Mi nombre _______ y quisiera consultar sobre su servicio...";

return (
		<footer className="footer">
			<div className="footer-grid">
				<img className="footer-logo animate__animated animate__fadeIn" src={ imagePath } alt="logo soto & iriarte abogados" />
				
				<div className="footer-column">
					<h3 className="footer-column-title">Navegación</h3>
					<Link className="footer-link" to="/laboral">Derecho Laboral</Link>
					<Link className="footer-link" to="/penal">Derecho Penal</Link>
					<Link className="footer-link" to="/civil">Derecho Civil</Link>
					<Link className="footer-link" to="/familia">Derecho de familia</Link>
					<Link className="footer-link" to="/tutoria">Tutorías</Link>
					<Link className="footer-link" to="/asesoria">Asesorías en general</Link>
				</div>


				<div className="footer-column">
					<h3 className="footer-column-title">Contacto</h3>
					<p className="footer-parraph">Teléfonos:</p>
					<a className="footer-a" href={ `tel:+56979033229`} >+569-79033229</a><a className="footer-a" href={ `tel:+56940030928`} >+569-40030928</a><a className="footer-a" href={ `tel:+56974728295`} >+569-74728295</a>
					<p className="footer-parraph">Correo:</p>
					<a className="footer-a" href={`mailto:${email}?subject=${encodeURIComponent(subject) || ''}&body=${encodeURIComponent(body) || ''}` }>{email}</a>
					<a className="link-social" href="https://www.instagram.com/sotoiriarte.abogados/" target="_blank" rel="noopener noreferrer">Instagram</a>
				</div>
			</div>
		</footer>
	);
};
