import React from 'react'
import { Link } from 'react-router-dom';

import '../../styles/especialidades/EspecialidadCard.css'

export const EspecilidadCard = ({
    id,
    name,
    desc,
}) => {
    
    const imagePath = `/assets/especialidades/${id}.jpg`;

    const description = desc.substring(0, 120).split(" ");
    description.pop();
    const formatedDescription = description.join(" ") + "...";



    return (
        <div className="cards-grid animate__animated animate__fadeIn ">
            <div className='card-img'>
                <img src={ imagePath } className="especialidad-image" alt={name}/>
            </div>
    
            <h5 className='card-title'>{name}</h5>
            <p className='card-desc'>{ formatedDescription }</p>


            <Link to={`/${id}`} className="card-link">
                Seguir leyendo... 
            </Link>
        </div>
    )
    }
    
