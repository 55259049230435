import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';

import { Categoria } from '../categorias/Categoria';

import { getEspecialidadById } from '../../selectors/getEspecialidadById';

import '../../styles/especialidades/EspecialidadScreen.css';

export const EspecialidadScreen = ( { vista = '/' } ) => {

    const navigate = useNavigate();
    const data = getEspecialidadById(vista);


    const { id, name, desc } = data;

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [vista]);

    return (
        <section className="esp-info animate__animated animate__fadeIn">
            <h1 className="esp-title"> { name } </h1>
            <hr />
            <h2 className="esp-desc"> { desc } </h2>

            <Categoria id={ id } />

            <div className="esp-more-info animate__animated animate__fadeIn">
                <h2 className='esp-title'> ¿Deseas más información?</h2>
                <h3 className='esp-subtitle'> Ponte en contacto con nosotros </h3>
                <button className='esp-btn' onClick={ () => navigate(`/contacto`) }> Contactar </button>
            </div>
        </section>
    )
}