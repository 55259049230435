
// const categoryLaboral = [
//     {
//         id: 'example',
//         name: 'Example',
//         description: 'Example description',
//     },
// ]

export const categoryLaboral = [
    {
        id: 'despido',
        name: 'Despido',
        description: 'Es una forma de acabar la relación laboral, si ello fue de forma improcedente, indebida o injustificada, será evaluado y resuelto por un juez laboral atendiendo a la causal de despido establecidas en la ley, pero aplica igual en los casos en que ni siquiera hubo causal, dando paso al pago diversas indemnizaciones las cuales incluso pueden ser aumentadas dependiendo de la causal invocada.'
    },
    {
        id: 'accidente',
        name: 'Accidentes de trabajo',
        description: 'El empleador está obligado por ley a resguardar la seguridad de sus trabajadores, por ello es que en caso que hubiese algún accidente laboral o enfermedad profesional procede la aplicación de multas, indemnizaciones, entre otras sanciones a este. La indemnización en estos casos demanda el perjuicio material o sea reclamar el daño, como el pago de los gastos que hubiese generado el accidente, por ejemplo gastos hospitalarios, pero además el lucro cesante del mismo, vale decir, lo que no podrá ganar debido al siniestro, sin perjuicio de ello también puede demandar la indemnización del daño moral o sea el dolor interno sufrido por el trabajador, sea por un tema personal, estético o incluso social, que siempre que surja como consecuencia del accidente.',
    },
    {
        id: 'derechos-fundamentales',
        name: 'Tutela derechos funtamentales',
        description: 'Este procedimiento busca resguardar las garantías fundamentales de los trabajadores, y se aplica para resolver conflictos causados por la vulneración de derechos fundamentales por parte del empleador, como son derecho a la vida e integridad física, vida privada, libertad de expresión, entre otras. \nA su vez es aplicable a los casos en que el empleador tome represalias por ejercer acciones judiciales, vale decir demandas, o por la labor fiscalizadora de la inspección del trabajo, debido a que estos casos conllevan afectación a tus derechos fundamentales como persona cualquier duda que tengas o si conoces a alguien que se haya visto vulnerado en sus derechos respecto a esta materia contáctanos.',
    },
    {
        id: 'autodespido',
        name: 'Autodespido',
        description: '¿Qué puedo hacer si mi empleador incurre en acosos y/o incumplimientos de contrato? En aquellos casos procede la figura del autodespido, por la cual el trabajador pone término al contrato de trabajo, en casos como acosos, injurias, conductas inmorales, actos que afecten la seguridad todas ejercidas por el empleador en contra del trabajador, entre otras.'
    },
    {
        id: 'analisis-contratos',
        name: 'Análisis de contratos laborales',
        description: 'Todos sabemos que el contrato de trabajo es un documento esencial en una relación laboral el cual establece la remuneración, funciones del trabajador, duración de jornada entre otros elementos, si tienes alguna duda sobre el tipo de contrato, tus derechos como trabajador, vacaciones, límites de la jornada o cualquier otra pregunta, contáctanos y te asesoraremos.'
    },

];