// const categoryPenal = [
//     {
//         id: 'example',
//         name: 'Example',
//         description: 'Example description',
//     },
// ]

export const categoryPenal = [
    {
        id: 'querella',
        name: 'Querella',
        description: 'Nuestro Código procesal penal establece el derecho de la víctima a presentar querella para poder intervenir en el proceso lo cual permite solicitar diligencias al fiscal, prisión preventiva del imputado, presentar pruebas entre otras ventajas. La víctima es el ofendido por el delito, sin embargo en los casos que no pueda ejercer sus derechos o que el delito conlleva la muerte de la misma, se considerará víctima además al cónyuge o al conviviente civil, a los hijos, ascendientes, conviviente, hermanos y por último adoptado o adoptante, en este orden, por lo cual no siempre es necesario ser quien haya sufrido el delito.',
    },
    {
        id: 'sexual',
        name: 'Delitos Sexuales',
        description: '',
    },
    {
        id: 'intrafamiliar',
        name: 'Violencia Intrafamiliar',
        description: 'En el caso en que una persona sufra violencia física o psicológica por parte de algún miembro de su propia familia, es posible iniciar medidas encaminadas a resguardar su integridad personal (sea esta física o psicológica) y la del resto del núcleo familiar. La VIF consiste en cualquier maltrato que afecte a la vida o integridad física o psicológica de una persona que tiene la calidad de cónyuge, ex cónyuge, conviviente, ex conviviente, padres, hijos, tíos, sobrinos, así como también respecto a menores de edad, discapacitados o adultos mayores que se encuentren bajo el cuidado o dependencia de cualquier integrante de la familia de quien realizó el acto violento. Entregamos asesoría oportuna para la tutela de su integridad y derechos al interior de la familia, pues esta es el núcleo fundamental de la sociedad.',
    },
    {
        id: 'robo',
        name: 'Hurto/Robo',
        description: 'En ambos casos existe una apropiación de alguna cosa ajena sin la voluntad del dueño y se establecen penas privativas de libertad. Pero en el caso del robo debe además ejercerse con violencia o intimidación sea sobre las cosas o las personas, vale decir, fuerza física o temor como el que surge de alguna amenaza, no así el hurto en el cual no es necesaria esta violencia o intimidación.',
    },
    {
        id: 'calumnia',
        name: 'Calumnias/Injurias',
        description: 'En ambos casos se establecen penas privativas de libertad y multas; Las injurias no son más que toda expresión proferida o acción ejecutada con el fin de deshonrar o desacreditar a otro, y las calumnias específicamente conllevan la imputación de un delito falso, es decir, que no se ha cometido, independiente si estás se han publicado o no, por ejemplo insultos a través de redes sociales.',
    },
    {
        id: 'adolescente',
        name: 'Responsabilidad penal adolescente',
        description: 'Para nosotros es de vital importancia ayudar y asesorar a menores en esta materia, están en una de las etapas más críticas de la vida y ya son responsables penalmente, la ley 20.084 refiere que se les aplica un régimen especial qué atendiendo al delito cometido, puede llegar desde una amonestación hasta una internación en régimen cerrado, si requieres guía o asesoría en esta materia no dudes en contactarnos.',
    },

]