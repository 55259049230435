import React, { useState, useMemo } from 'react'
import { Accordion } from 'react-bootstrap';

import { getCategoriaById } from '../../selectors/getCategoriaById';

export const Subcategoria = ( { id = '', id_category= '', category = '' } ) => {
    const subcategorias = useMemo( () => getCategoriaById( id, 'subcategory' ), [ id ] );
    const Subsubcategoria = useMemo( () => getCategoriaById( id, 'subsubcategory' ), [ id ] );

    const [counter, setCounter] = useState(-1)
    
    const generateEventKey = () => {
        setCounter(counter + 1)
        return `${counter}`
    } 
    
    const itemData = subcategorias.find( subcategoria => { return subcategoria.category === category } );
    const subCatItemFiltered = Subsubcategoria.filter( subcat => { return subcat.id === itemData.category });
    return (
        <Accordion key={ `${id}-${id_category}` } alwaysOpen className='acordion' >
            <Accordion.Item key={ `${id_category}-item` } eventKey={ generateEventKey } >
                <Accordion.Header key={ `${id_category}-header` } > { itemData.name } </Accordion.Header>
                <Accordion.Body key={ `${id_category}-body` } >
                    { itemData.description }
                    {
                        !!subCatItemFiltered.length &&
                            subCatItemFiltered.map( item => (
                                <Accordion key={ `${category}-${ item.key }` } alwaysOpen className='acordion' >
                                    <Accordion.Item key={ `${item.id}-item` } eventKey={ generateEventKey } >
                                        <Accordion.Header key={ `${item.id}-header` } > { item.name } </Accordion.Header>
                                        <Accordion.Body key={ `${item.id}-body` } >
                                            { item.description }
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            ))
                    }
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    )
}
