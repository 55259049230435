
export const abogados = [
    {
        id: 'sotoValenzuela',
        name: 'Jorge Soto Valenzuela',
        img: 'jorge-soto',
        description: 'Abogado y Licenciado en Ciencias Jurídicas y Sociales de la Universidad Santo Tomás, actualmente estudiante de Magíster en Derecho, mención Derecho Penal y Procesal Penal de la Universidad Católica del Norte. Especialista en Derecho Penal, desde su práctica profesional en el Centro de Atención a Víctimas de Delitos Violentos de la Corporación de Asistencia Judicial definió su perfil profesional. Cuenta, además, con habilidades de docencia, lo que ha plasmado a través del tiempo en ayudantías, cátedras y tutorías. Es en este sentido que también figura como coautor de la investigación “Grupo Intermedio Vestigium: el Derecho a través del Constructivismo”, investigación dirigida a la pedagogía jurídica y presentada en el Congreso de Educación y Derecho de la Universidad de Atacama, Copiapó.',
        especialidad: 'Abogado, Universidad Santo Tomás',
        slogan: 'En SOTO & IRIARTE ABOGADOS comprendemos que cada proceso y cada persona es diferente, por lo que creemos esencial la especialización de nuestra gestión y asesoría. En este sentido, enfatizamos en la información y el buen trato con nuestro cliente, a través del respeto a su dignidad y la confidencialidad de la información que se nos otorga. En SOTO & IRIARTE ABOGADOS, nos aseguramos de que su caso sea llevado por profesionales especializados e informados y preparados.',
        contacto: {
            telefono: '+569-40030928',
            email: 'jsoto@sotoiriarteabogados.cl'
        }
    },
    {
        id: 'iriarteHidalgo',
        name: 'Sergio Iriarte Hidalgo',
        img: 'sergio-iriarte',
        description: 'Abogado, licenciado en ciencias jurídicas y sociales de la Universidad Santo Tomás de La Serena; quién ejerció su práctica profesional como abogado de víctimas en el Centro de atención integral a víctimas de delitos violentos de la corporación de asistencia judicial de la cuarta región, por ello es que le permitió desarrollar no solo la atención en la asesoría jurídica por parte de las personas que llegasen al centro si no un alto desempeño en el apoyo y reparación emocional de las mismas; a su vez participó en dos exposiciones y conversatorios efectuados a escuelas sobre la temática de “Ciberbullying” y “Ley de responsabilidad penal adolescente”; y ejerce como preparador titular de exámenes de grado en la universidad santo Tomás de La Serena teniendo presente siempre su vocación docente , y teniendo como pilares fundamentales en el ejercicio de la profesión el compromiso, dedicación y sobre todo la responsabilidad.',
        especialidad: 'Abogado, Universidad Santo Tomás',
        slogan: 'En SOTO & IRIARTE ABOGADOS comprendemos que cada proceso y cada persona es diferente, por lo que creemos esencial la especialización de nuestra gestión y asesoría. En este sentido, enfatizamos en la información y el buen trato con nuestro cliente, a través del respeto a su dignidad y la confidencialidad de la información que se nos otorga. En SOTO & IRIARTE ABOGADOS, nos aseguramos de que su caso sea llevado por profesionales especializados e informados y preparados.',
        contacto: {
            telefono: '+569-79033229',
            email: 'siriarte@sotoiriarteabogados.cl'
        }
    },
    {
        id: 'brooksCaroca',
        name: 'Lenka Brooks Caroca',
        img: 'lenka-brooks',
        description: 'Abogada y Licenciada en Ciencias Jurídicas y Sociales de la Universidad Santo Tomás de la ciudad de La Serena, quien realizó su práctica profesional en calidad de abogada en la Corporación de Asistencia Judicial en el área de Familia de La Serena. Esto le permitió entrar en contacto directo con los problemas cotidianos de las personas, debiendo otorgarles una solución jurídica de forma adecuada, rápida y eficaz tomando en consideración las necesidades propias de cada uno de los usuarios que llegaban a su puerta, brindándoles apoyo emocional y una escucha activa, teniendo como pilar fundamental no solo la responsabilidad sino que además el respeto a la dignidad de las personas. Además se desempeña como Juez Árbitro habilitada por el Poder Judicial desde inicios del año 2023.',
        especialidad: 'Abogada, Universidad Santo Tomás. Juez árbitro.',
        slogan: 'En SOTO & IRIARTE ABOGADOS comprendemos que cada proceso y cada persona es diferente, por lo que creemos esencial la especialización de nuestra gestión y asesoría. En este sentido, enfatizamos en la información y el buen trato con nuestro cliente, a través del respeto a su dignidad y la confidencialidad de la información que se nos otorga. En SOTO & IRIARTE ABOGADOS, nos aseguramos de que su caso sea llevado por profesionales especializados e informados y preparados.',
        contacto: {
            telefono: '+569-74728295',
            email: 'lbrooks@sotoiriarteabogados.cl'
        }
    }

]