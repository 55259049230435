import React from 'react'

import { nosotros } from '../../data/nosotros'

import '../../styles/nosotros/NosotrosScreen.css'

export const NosotrosScreen = () => {
    
    const { parrafo, parrafo2 } = nosotros[0];

    return (
        <div className="nosotros">
            <h1 className="nosotros-title"> Nosotros </h1>
            <hr />
            <p className='nosotros-parraph'>{ parrafo }</p>
            <p className='nosotros-parraph'>{ parrafo2 }</p>
        </div>
    )
}
