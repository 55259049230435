import React, { useMemo, useState } from 'react'
import { Accordion } from 'react-bootstrap';

import { getCategoriaById } from '../../selectors/getCategoriaById';
import { obtainItemSubcategory } from '../../helpers/obtainItemSubcategory';

import { Subcategoria } from './Subcategoria';

export const Categoria = ( { id = '' } ) => {

    const categorias = useMemo( () => getCategoriaById( id, 'category' ), [ id ] );
    const subcategorias = useMemo( () => getCategoriaById( id, 'subcategory' ), [ id ] );
    
    const [counter, setCounter] = useState(-1)
    
    const generateEventKey = () => {
        setCounter(counter + 1)
        return `${counter}`
    } 
    return (
        <div className="categorias">
            {
                !!categorias.length &&
                categorias.map( cat => (
                    <Accordion key={ `${id}-${cat.id}` } alwaysOpen flush className='acordion' >
                        <Accordion.Item key={ `${cat.id}-item` } eventKey={ generateEventKey } >
                            <Accordion.Header key={ `${cat.id}-header` } > { cat.name } </Accordion.Header>
                            <Accordion.Body key={ `${cat.id}-body` } > 
                                { cat.description } 
                                {
                                    !!obtainItemSubcategory( cat.id, subcategorias ).length && 
                                        obtainItemSubcategory(cat.id, subcategorias ).map( subcat => (
                                            <Subcategoria key={ `${subcat.category}-subcategoria` } id ={ id } id_category={ subcat.id } category={ subcat.category }/>
                                        ))
                                }
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                ))
            }
        </div>
        )  
}
