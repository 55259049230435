import React from 'react';
import { Route, Routes, BrowserRouter} from 'react-router-dom';

import { Navbar } from '../components/ui/Navbar';
import { Principal } from '../components/principal/Principal';
import { Footer } from '../components/footer/Footer';
import { AbogadoScreen } from '../components/abogados/AbogadoScreen';
import { EspecialidadScreen } from '../components/especialidades/EspecialidadScreen';
import { ContactoScreen } from '../components/contacto/ContactoScreen';


export const AppRouter = () => {
  return (
      <BrowserRouter>
        <Navbar />

        <Routes>
            <Route path="/" element={<Principal />} />
            <Route path="penal" element={<EspecialidadScreen vista = "penal" />} />
            <Route path="laboral" element={<EspecialidadScreen vista = "laboral" />} />
            <Route path="civil" element={<EspecialidadScreen vista = "civil" />} />
            <Route path="tutoria" element={<EspecialidadScreen vista = "tutoria" />} />
            <Route path="asesoria" element={<EspecialidadScreen vista = "asesoria" />} />
            <Route path="abogado/:abogadoId" element={<AbogadoScreen />} />
            <Route path="contacto" element={<ContactoScreen />} />
            <Route path="familia" element={<EspecialidadScreen vista = "familia" />} />

            <Route path="/*" element={<Principal />} />
        </Routes>

        <Footer />
    </BrowserRouter>
  )
}
