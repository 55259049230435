
export const especialidades = [
    {
        id: 'penal',
        name: 'Derecho Penal',
        desc: 'Preparación y tramitación de querellas penales, representación de la víctima, preparación judicial en todo tipo de procesos, con especialización en delitos sexuales como la violación, el estupro y los abusos sexuales, además de lesiones, amenazas, homicidio, delitos patrimoniales como el robo o el hurto, cuasidelitos y responsabilidad penal adolescente.',
    },
    {
        id: 'laboral',
        name: 'Derecho Laboral',
        desc: 'Asesoría en análisis de contratos de trabajo, término de relación laboral, desarrollo de estrategias de defensa en conflictos laborales y previsionales, demandas laborales y tutela de derechos fundamentales por despidos injustificados o improcedentes, indemnizaciones y pago de cotizaciones previsionales.'
    },
    {	
        id: 'civil',
        name: 'Derecho Civil',
        desc: 'Si estás pensando en celebrar un contrato, nosotros te ayudamos en su redacción o su revisión, ya sea en contratos de arriendo, promesa, mandatos, compraventas o hipotecas, entre otros. También hacemos estudios de títulos inmobiliarios para compraventa de inmuebles y te ayudamos con la tramitación de posesiones efectivas ante el Servicio de Registro Civil o ante los Tribunales civiles.',
    },
    {
        id: 'familia',
        name: 'Derecho de Familia',
        desc: 'Asesoría Jurídica en divorcios, alimentos, relación directa y regular, cuidado personal, Acción de Reclamación e Impugnabilidad de Paternidad, adopción, violencia intrafamiliar, medidas de protección, bienes familiares y autorización del menor para salir del país.',
    },
    {
        id: 'tutoria',
        name: 'Tutorías',
        desc: 'Si estás en proceso de preparación de tu examen de grado, ofrecemos tutorías con especial énfasis en Derecho Civil y Procesal. También te ayudamos a preparar la cédula y ofrecemos tutorías en Derecho Civil, Procesal, Laboral y Penal para todos los estudiantes de Derecho que así lo requieran.',
    },
    {
        id: 'asesoria',
        name: 'Asesoría jurídica en general',
        desc: 'En general tenemos la disposición para asesorarte en cualquier tipo de materias jurídicas en las cuales tuvieses dudas como por ejemplo cambios de nombre, amenazas o lesiones que hayas sufrido, detenciones ilegales, entre otros ámbitos, consúltanos y analizaremos tu caso para determinar jurídicamente la forma de actuar al respecto. ',
    },
]