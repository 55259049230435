import React from 'react'
import { especialidades } from '../../data/especialidades'
import { EspecilidadCard } from './EspecilidadCard'

import '../../styles/especialidades/EspecialidadList.css'

export const EspecialidadList = () => {

    return (
        <section className="especialidades">
            <h2 className="especialidades-title">Servicios</h2>
            <hr />
            <div className="especialidad-grid animate__animated animate__fadeIn">
                {
                    especialidades.map( esp => {
                        return <EspecilidadCard key={esp.id} { ...esp }/>
                    })
                }
            </div> 
        </section>
  )
}
