
export const contacto = [
    {
        email: 'contacto@sotoiriarteabogados.cl',
        subject: 'Consulta sobre ...',
        body: 'Saludos mi nombre es ... \nQuisiera hacer una consulta sobre ...'
    },
    {
        telefono: { tel1: '+569-40030928', tel2: '+569-79033229', tel3:'+569-74728295'}
    },
    {
        urlMap: 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3458.714848283999!2d-71.253691!3d-29.901316000000005!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9691ca70c453178d%3A0x72632843f4554ab8!2sPedro%20Pablo%20Mu%C3%B1oz%20340%2C%201710083%20La%20Serena%2C%20Coquimbo!5e0!3m2!1ses-419!2scl!4v1710858359889!5m2!1ses-419!2scl',
        direccion: 'Pedro Pablo Muñoz 340, 2° piso, La Serena, Chile.'
    }
];

