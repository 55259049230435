import React, { useEffect } from 'react'

import { AbogadoList } from '../abogados/AbogadoList';
import { Mailto } from './Mailto';
import { Makecall } from './Makecall';
import { contacto } from '../../data/contacto';

import '../../styles/contacto/ContactoScreen.css';

export const ContactoScreen = () => {

    const { email, subject, body } = contacto[0];

    const { telefono } = contacto[1];
    const { tel1, tel2, tel3 } = telefono;
    const { urlMap, direccion } = contacto[2]; 

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <section className='contacto animate__animated animate__fadeIn'>
            <h1 className='contacto-title'> Contacto </h1>
            <hr />
            <div className='contacto-info'>
                <h2 className='contacto-info-title'>Ponte en contracto con nosotros<br></br>¡le daremos una solución a tu problema!</h2>
                <h3 className='contacto-info-subtitle'>Nuestros Horarios:</h3>
                <p className='contacto-info-parraph'>Lunes a Viernes desde las 10:00 hasta las 16:00 (Horario continuado)</p>
            </div>

            <div className='contacto-mapa'>
                <p className='contacto-info-parraph'>Estamos ubicados en <strong>{direccion}</strong></p>
                <iframe 
                    style={{}}
                    src={urlMap}
                    loading="lazy" 
                    allowFullScreen=""
                    referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
            </div>

            <div className='contacto-grid animate__animated animate__fadeIn'>
                <div className='contacto-item-grid'>
                    <h2 className='contacto-item-title'>¡Envíanos un correo!</h2>
                    <p className='contacto-item-parraph'>Nos pondremos en contacto contigo lo antes posible</p>
                    <Mailto email={email} subject={subject} body={body}>Enviar un correo</Mailto>
                </div>
                <div className='contacto-item-grid'>
                    <h2 className='contacto-item-title'>¿Prefieres llamarnos?</h2>
                    <p className='contacto-item-parraph'> Ponte en contacto con nosotros en los siguientes números </p>
                    <div className='contacto-item-tel'>
                        <Makecall className="btn-contacto-tel" telefono={tel1}>{tel1}</Makecall>
                        <Makecall className="btn-contacto-tel" telefono={tel2}>{tel2}</Makecall>
                        <Makecall className="btn-contacto-tel" telefono={tel3}>{tel3}</Makecall>
                    </div>
                </div>
            </div>

            <div className="contacto-abogados">
                <h4 className='contacto-info-title'>Nuestros abogados están listos para tomar tu caso</h4>
                <AbogadoList />
            </div>
        </section>
    )
}



