import React, { useEffect, useMemo } from 'react'
import { Navigate, useNavigate, useParams } from 'react-router-dom';

import { Mailto } from '../contacto/Mailto';

import { getAbogadoById } from '../../selectors/getAbogadoById';

import '../../styles/abogados/AbogadoScreen.css';
import { Makecall } from '../contacto/Makecall';

export const AbogadoScreen = () => {
    const navigate = useNavigate();
    const { abogadoId } = useParams();
    const abogado = useMemo(() => getAbogadoById(abogadoId), [abogadoId])

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [abogado])

    if ( !abogado ){
        return <Navigate to='/' />
    }

    const { id, name, img, description, especialidad, contacto, slogan } = abogado;

    const handleReturn = () => {
        navigate(-1);
    }


    return (
        <section className='abogado'>
                <div className='ab-title'>
                    <h1>{name}</h1>
                    <h5>{especialidad}</h5>
                    <hr />
                </div>
            <div className='ab-grid'>
                {/* <img className='ab-img animate__animated animate__fadeInLeft' src={`/assets/abogados/${img}.jpg`} alt={name} /> */}
                <img className='ab-img animate__animated animate__fadeInLeft' src={`/assets/abogados/user.png`} alt={name} />

                <div className='ab-info animate__animated animate__fadeIn'>
                    <p>{description}</p>
                    <hr />
                    <p>Teléfono: {contacto.telefono}</p>
                    <hr />
                    <p>Correo: {contacto.email}</p>
                    <hr />
                    <div className='ab-buttons'>
                        <button className='btn-contacto btn-back' onClick={ handleReturn }>Volver</button>
                        <Makecall telefono={contacto.telefono}>Llamar</Makecall>
                    </div>
            </div>
        </div>

        <div className="ab-slogan">
            <h3 className='slogan-title'>{slogan}</h3>
        </div>
        </section>
    )
}
