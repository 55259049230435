
/*
    * Obtain the subcategory of a category
    * @param {string} id
    * @param {array} subcategories
    * @return {array}
*/
export const obtainItemSubcategory = ( id, arr ) => {
    return arr.filter( subcategoria => { return subcategoria.id === id } )
}
