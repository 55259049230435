
// const categoryTutoria = [
//     {
//         id: 'example',
//         name: 'Example',
//         description: 'Example description',
//     },
// ]


export const categoryTutoria = [
    {
        id: 'civil',
        name: 'Derecho Civil',
        description: 'Te podremos guiar en general con todas las materias civiles como teoría de la ley, acto jurídico, bienes, modos de adquirir el dominio, teoría de las obligaciones, responsabilidad, contratos en particular, derecho de familia y sucesorio, entre otras teniendo en cuenta el gran abanico de temáticas que posee el derecho civil.',
    },
    {
        id: 'procesal-penal',
        name: 'Derecho Procesal civil y Penal',
        description: 'Te ayudaremos en general en materias de procesal civil como jurisdicción y competencia, juicio ordinario de mayor cuantía, juicio sumario, juicio ejecutivo, recursos entre otras unidades que comprenden el derecho procesal, y además orientarte en procesal penal tanto en su procedimiento ordinario, como en procedimiento simplificado y abreviado, prisión preventiva, querellante, recursos, etc.',
    },
    {
        id: 'cedulas',
        name: 'Preparación de cédulas',
        description: 'Sin perjuicio de que las cédulas cambian atendiendo la institución educacional en la cual estás cursando la carrera, no dudes en consultarnos sobre este tema que es esencial en el grado, el cual tú debes preparar para partir con buen pie tú examen, por ello es que podemos orientarte tanto en derecho penal parte general y especial, derecho comercial, derecho laboral, derecho administrativo y derecho constitucional, entre otros.',
    }
];