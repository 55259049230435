import React from 'react'

import { AppRouter } from './routers/AppRouter'


export const SotoIriarteApp = () => {
  return (
    <AppRouter />
  )
}
