import React, { useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';

import '../../styles/ui/Navbar.css';

export const Navbar = () => {

    const [isNavCollapsed, setIsNavCollapsed] = useState(true);

    const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);

    useEffect(() => {
        setIsNavCollapsed(true);
      }, [])

    return (
        <div className='nav-container'>
            <nav className="navbar navbar-expand-lg navbar-dark fixed-top">
                
                <Link 
                    className="navbar-brand mg-1 animate__animated animate__pulse" 
                    to="/"
                >
                    Soto & Iriarte abogados
                </Link>

                <button className="custom-toggler navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarItems" aria-controls="navbarsExample09" aria-expanded={!isNavCollapsed ? true : false} aria-label="Toggle navigation" onClick={handleNavCollapse}>
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className={`${isNavCollapsed ? 'collapse' : ''} navbar-collapse justify-content-end`} id="navbarItems">
                    <div className="navbar-nav">
                            <NavLink 
                                className={ ({ isActive }) => "nav-item nav-link " + ( isActive ? 'active' : '' ) } 
                                to="/contacto"
                                onClick={ handleNavCollapse }
                            >
                                Contacto
                            </NavLink>
                            
                            <NavLink 
                                className={ ({ isActive }) => "nav-item nav-link " + ( isActive ? 'active' : '' ) } 
                                to="/penal"
                                onClick={ handleNavCollapse }
                            >
                                Derecho penal
                            </NavLink>

                            <NavLink 
                                className={ ({ isActive }) => "nav-item nav-link " + ( isActive ? 'active' : '' ) } 
                                to="/laboral"
                                onClick={ handleNavCollapse }
                            >
                                Derecho laboral
                            </NavLink>
                            
                            <NavLink 
                                className={ ({ isActive }) => "nav-item nav-link " + ( isActive ? 'active' : '' ) } 
                                to="/civil"
                                onClick={ handleNavCollapse }
                            >
                                Derecho civil
                            </NavLink>

                            <NavLink 
                                className={ ({ isActive }) => "end-nav nav-item nav-link " + ( isActive ? 'active' : '' ) } 
                                to="/familia"
                                onClick={ handleNavCollapse }
                            >
                                Derecho de familia
                            </NavLink>

                            <NavLink 
                                className={ ({ isActive }) => "end-nav nav-item nav-link " + ( isActive ? 'active' : '' ) } 
                                to="/tutoria"
                                onClick={ handleNavCollapse }
                            >
                                Tutorías
                            </NavLink>
                            <NavLink 
                                className={ ({ isActive }) => "end-nav nav-item nav-link " + ( isActive ? 'active' : '' ) } 
                                to="/asesoria"
                                onClick={ handleNavCollapse }>
                                    Asesorías en general  
                            </NavLink>
                    </div>
                </div>
            </nav>
        </div>  
  )
}
