import React from 'react'

import "../../styles/portada/PortadaScreen.css";

export const PortadaScreen = () => {
    const portadaImg = "/assets/logo/logo.png";
    return (
        <div className="portada">
            <div className="img-wrapper">
                <img className="portada-img" src={portadaImg} alt="Logo de soto & iriarte abogados y de fondo el mapa de la IV región" />
            </div>
        </div>
    )
}
