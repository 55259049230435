//import categorias
import { categoryPenal } from "../data/categorias/categoria/penal";
import { categoryCivil } from "../data/categorias/categoria/civil";
import { categoryLaboral } from "../data/categorias/categoria/laboral";
import { categoryTutoria } from "../data/categorias/categoria/tutoria";
import { categoryAsesoria } from "../data/categorias/categoria/asesoria";
import { categoryFamilia } from "../data/categorias/categoria/familia";
//import subcategorias
import { subPenal } from "../data/categorias/subcategoria/s_penal";
import { subCivil } from "../data/categorias/subcategoria/s_civil";
import { subLaboral } from "../data/categorias/subcategoria/s_laboral";
import { subTutoria } from "../data/categorias/subcategoria/s_tutoria";
import { subAsesoria } from "../data/categorias/subcategoria/s_asesoria";
import { subFamilia } from "../data/categorias/subcategoria/s_familia";
//import subsubcategorias
import { subSubPenal } from "../data/categorias/subsubcategoria/ss_penal";
import { subSubCivil } from "../data/categorias/subsubcategoria/ss_civil";
import { subSubLaboral } from "../data/categorias/subsubcategoria/ss_laboral";
import { subSubTutoria } from "../data/categorias/subsubcategoria/ss_tutoria";
import { subSubAsesoria } from "../data/categorias/subsubcategoria/ss_asesoria";
import { subSubFamilia } from "../data/categorias/subsubcategoria/ss_familia";


export const getObjectByCategory = ( id ) => {
    switch ( id ) {
        case 'penal':
            return categoryPenal;
        case 'civil':
            return categoryCivil;
        case 'laboral':
            return categoryLaboral;
        case 'tutoria':
            return categoryTutoria;
        case 'asesoria':
            return categoryAsesoria;
        case 'familia':
            return categoryFamilia;
        default:
            return {};
    }
}

export const getObjectBySubcategory = ( id ) => {
    switch ( id ) {
        case 'penal':
            return subPenal;
        case 'civil':
            return subCivil;
        case 'laboral':
            return subLaboral;
        case 'tutoria':
            return subTutoria;
        case 'asesoria':
            return subAsesoria;
        case 'familia':
            return subFamilia;
        default:
            return {};
    }
}

export const getObjectBySubsubcategory = ( id ) => {
    switch ( id ) {
        case 'penal':
            return subSubPenal;
        case 'civil':
            return subSubCivil;
        case 'laboral':
            return subSubLaboral;
        case 'tutoria':
            return subSubTutoria;
        case 'asesoria':
            return subSubAsesoria;
        case 'familia':
            return subSubFamilia;
        default:
            return {};
    }
}
