
// const categoryCivil = [
//     {
//         id: 'example',
//         name: 'Example',
//         description: 'Example description',
//     },
// ]

export const categoryCivil = [
    {
        id: 'redaccion',
        name: 'Redacción y revisión de contratos',
        description: 'Si vas a celebrar un contrato nosotros te podemos ayudar tanto en su redacción como a través de una revisión, sean contratos de arriendo, promesa, mandatos, compraventas, hipotecas, entre otros.',
    },
    {
        id: 'estudios',
        name: 'Estudio de títulos inmobiliarios ',
        description: 'Es un examen detallado y minucioso de todos los antecedentes de un inmueble, de manera que se pueda determinar cuál es su situación jurídica actual y los derechos que recaen sobre el mismo. En este estudio se determinará quién es el dueño actual del inmueble y si se encuentra en condiciones de ser transferido o tiene alguna prohibición legal que lo impida. Además, también se determinará si existe un tercero que se encuentre en posición de reclamar un mejor derecho sobre este inmueble.',
    },
    {
        id: 'herencao',
        name: 'Herencia ',
        description: 'Habiendo o no testamento podremos ayudarte con la tramitación de la posesión efectiva, teniendo en cuenta que si hay testamento conlleva un procedimiento judicial no contencioso, en cambio si es intestada va vinculada a una resolución administrativa por parte del registro civil, cualquier duda en general sobre esta materia consúltanos.',
    },
];