// const subCategory = [
//     {
//         id: 'example',
//         category: 'subCategory_id',
//         name: 'subcategory title',
//         description: 'subcategory description',
//     }
// ]

export const subPenal = [
    {
        id: 'sexual',
        category: 'violacion',
        name: 'Violación',
        description: 'Nuestro Código Penal establece penas privativas de libertad. Esta conlleva que una persona acceda carnalmente vía vaginal, anal o bucal, a alguien mayor de 14 años habiendo uso de la fuerza o intimidación, o que la víctima esté privada de sentido o se aproveche de su incapacidad para oponer resistencia o de algún trastorno mental de la misma; estás circunstancias no se requieren en caso de afectar a una persona menor de 14 años',
    },
    {
        id: 'sexual',
        category: 'estupro',
        name: 'Estupro',
        description: 'Establecido en el art. 363 del Código Penal, este refiere que es el acceso carnal, vía vaginal, anal o bucal, respecto de una persona que tenga entre 14 y 17 años, en los casos que se haya abusado de una perturbación mental, relación de dependencia, grave desamparo de la víctima o cuando es engañada por su inexperiencia o ignorancia sexual, en todos estos casos estableciendo penas privativas de libertad.'
    },
    {
        id: 'sexual',
        category: 'abuso-sexual',
        name: 'Abuso sexual',
        description: 'Regulado en el art. 366 del Código Penal, este consiste en una acción sexual distinta del acceso carnal, esto es que habiendo contacto corporal o no, cualquier acto de significación sexual, que respecto de una persona mayor de 14 años deben concurrir las mismas circunstancias de la violación y del estupro (Revisa el apartado de aquellos delitos en esta misma página), pero respecto de una persona menor de 14 años no son necesarias aquellas circunstancias, en ambos casos sancionándolo con penas privativas de libertad.'
    },
    {
        id: 'sexual',
        category: 'acoso-sexual',
        name: 'Acoso sexual',
        description: 'Implementado por la ley 21.153, se constituye en caso de que alguien en lugares públicos o de libre acceso al público, por cualquier medio capte, grabe o fotografíe imágenes, videos, de los genitales u otra parte íntima de otra persona sin su consentimiento, sin perjuicio de ello comete acoso sexual callejero aquel que realice actos verbales o gestuales, acercamientos, persecuciones o cualquier acto de significación sexual que intimide o humille y que no constituya otra falta o delito al que se le imponga una pena mayor, estableciendo en ambos casos penas privativas de libertad y multa atendiendo ciertas circunstancias.'
    }
]