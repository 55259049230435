import React from 'react'

// import '../../styles/contacto/ContactoButtons.css'


export const Mailto = ({ email, subject, body, children }) => {

        return (
            <a className="btn-contacto" href={`mailto:${email}?subject=${encodeURIComponent(subject) || ''}&body=${encodeURIComponent(body) || ''}`}>{children}</a>
        );
};
