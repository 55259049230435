
import { getObjectByCategory, getObjectBySubcategory, getObjectBySubsubcategory  } from "./getObjectByCategories";


export const getCategoriaById = ( id, type ) => {
 
    switch ( type ) {
        case 'category':
            return getObjectByCategory( id );
        case 'subcategory':
            return getObjectBySubcategory( id );
        case 'subsubcategory':
            return getObjectBySubsubcategory( id );
        default:
            return {};
    }
}