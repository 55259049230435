import React from 'react'
import { abogados } from '../../data/abogados';
import { AbogadoCard } from './AbogadoCard';

import '../../styles/abogados/AbogadoList.css';

export const AbogadoList = () => {
    return (
        <section className='abogados'>
            <div className='abogados-grid animate__animated animate__fadeIn'>
                {
                    abogados.map( abogado => {
                      return <AbogadoCard key={abogado.id} { ...abogado }/>
                    })
                }
            </div>
        </section>
    )
}